// USA
export const localeEn = {
  lang: 'en',
  data: {
    MENU: {
      WELCOME: 'Welcome Page',
      DASHBOARD: 'Dashboard',
      INSPECTION_TRACKER: 'Inspection Tracker',
      DASHBOARD_INSPECTION_TRACKER: 'Dashboard Inspection Tracker',
      CSO_TERMINAL: 'CSO Terminal',
      DASHBOARD_CSO_TERMINAL: 'Dashboard CSO Terminal',
      SKP_DISTRIBUTOR: 'SKP Distributor',
      DASHBOARD_SKP_DISTRIBUTOR: 'Dashboard SKP Distributor',
      MICROSITE: 'Mikrosite',
      DOCUMENT_LICENSE: 'Document and License',
      LIST_TO_ASSIGN: 'List to Assign',
      INSPECTION: 'Inspection Company',
      INSPECTION_BOOK_MIGAS: 'Complete Inspection Book',
      SIGN_BA_INSPECTION_DIST: 'Sign BA Inspection (Distributor)',
      SIGN_BA_INSPECTION_EMLI: 'Sign BA Inspection (EMLI)',
      FINDING_TICKET: 'Finding Ticket',
      COI: 'COI',
      SKHP_ANNUAL_TERRA_REPEAT: 'SKHP Annual Terra Repeat',
      COI_RENEWAL: 'COI Renewal',
      STOP_PROCESS_REQUEST: 'Stop Process Request',
      TERMINAL_LIFTING_REPORT: 'Terminal Lifting Report',
      TERMINAL_RECEIVE_REPORT: 'Terminal Receive Report',
      TERMINAL_MONTHLY_REPORT: 'Terminal Monthly Report',
      TANK_DATA: 'Tank Data',
      DATA_MONITORING: 'Data Monitoring',
      DATA_MONITORING_MIGAS: 'Compulsory Stock Obligation BPH Migas',
      DG_MIGAS: 'DG Migas Reporting',
      ACCESS_MANAGEMENT: 'Access Management',
      PREPARE_INSPECTION_BOOK: 'Inspection Book Reporting',
      CUSTOMER_LIST: 'Customer List',
      STOCK_CALCULATION_REPORT: 'Stock Calculation Report',
      REPORT_BUILDER: 'Report Builder',
      REPORT_BUILDER_DISTRIBUTOR: 'Distributor',
      REPORT_BUILDER_CSO: 'CSO',
      REPORT_BUILDER_INSPECTION: 'Inspection'
    },
    PAGE: {
      TITLE: {
        ROLE_ACCESS: 'Role Access',
        DG_MIGAS: 'DG Migas Reporting',
        DEFINE_ROLE_ACCESS: 'Define Role Access',
        EDIT_ROLE_ACCESS: 'Edit Role Access',
        MICROSITE: 'Mikrosite List',
        MICROSITE_DETAIL: 'Mikrosite Detail',
        UPLOAD_MICROSITE: 'Upload Mikrosite',
        CREATE_MICROSITE: 'Create New Mikrosite',
        EDIT_MICROSITE: 'Edit Mikrosite',
        DOCUMENT_LICENSE: 'Document and License',
        DOCUMENT_LICENSE_DETAIL: 'Detail Document and License',
        UPLOAD_DOCUMENT_LICENSE: 'Upload Document and License',
        LIST_TO_ASSIGN: 'Mikrosite List to Assign',
        LIST_TO_ASSIGN_UPLOAD: 'Upload List to Assign',
        DETAIL_ASSIGN: 'Detail Assign Inspection',
        INSPECTION_COMPANY_LIST: 'Inspection Company List',
        INSPECTION_DATA_INPUT: 'Inspection Data Input',
        INSPECTION_BOOK_MIGAS_LIST: 'Safety Inspection Result Reports',
        INSPECTION_BOOK_MIGAS_DETAIL: 'Inspection Book Detail',
        DETAIL_REVIEW: 'Detail Review',
        SIGN_BA_INSPECTION_LIST: 'Sign BA Inspection List',
        SIGNATURE_DOCUMENT_LIST: 'Signature Document List',
        SITE_INFO: 'Site Info',
        COI_LIST: 'COI LIST',
        UPLOAD_COI: 'Upload COI',
        COI_DETAIL: 'COI Detail',
        COI_RENEWAL_LIST: 'COI Renewal List',
        PREPARE_INSPECTION_BOOK: 'Inspection Book Reporting',
        DEFINE_DOCUMENT: 'Define Document',
        TERMINAL_LIFTING_REPORT: 'Terminal Lifting Report',
        TERMINAL_RECEIVE_REPORT: 'Terminal Receive Report',
        INSPECTION_DATA_DETAIL: 'Data Detail',
        UPLOAD_TERMINAL_DATA: 'Upload Terminal Data',
        UPLOAD_TANK_DATA: 'Upload Tank Data',
        STOCK_CALCULATION_REPORT: 'Stock Calculation Report',
        MONTHLY_STOCK_CALCULATION: 'Monthly Stock Calculation',
        QUARTERLY_STOCK_CALCULATION: 'Quarterly Stock Calculation',
        REPORT_BUILDER: 'Report Builder',
        REPORT_BUILDER_DISTRIBUTOR: 'Report Builder Distributor',
        REPORT_BUILDER_CSO: 'Report Builder CSO',
        REPORT_BUILDER_INSPECTION: 'Report Builder Inspection'
      },
      LABEL: {
        ROLE_NAME: 'Role Name',
        ACCESS_MENU: 'Access Menu',
        UPLOAD_FILE: 'Upload File',
        FILE: 'File',
      },
      HI: 'Hi, {{name}}',
      LONG_TEXT: {
        WELCOME_MSG: 'Welcome to Integrated Mikrosite Management',
        WELCOME_MSG_DESC:
          'IM2 (Integrated Mikrosites Management) application allows users to easily upload data from various sources, such as files, databases, and Document Image. It supports a wide range of data formats and ensures data integrity during the request permission mikrosite process.',
      },
    },
    MODAL: {
      TITLE: {
        EM_SALES_ACCESS_MENU: 'EM Sales Access Menu',
        CONFIRM_DELETE_IMAGE: 'Are you sure want to delete images?',
        CONFIRM_DELETE_VIDEO: 'Are you sure want to delete videos?',
        CONFIRM_INSPECTION_DATA: 'Are you sure, want to continue inspection data?',
        LEAVE_PAGE: 'Leave page with unsaved changes?',
        DELETE_CONFIRMATION: "Delete {{title}}?",
      },
      TEXT: {
        CONFIRM_DELETE_IMAGE: 'Confirm this page will delete the images that you choosen. <br>Would you like to confirm or cancel the action?',
        CONFIRM_DELETE_VIDEO: 'Confirm this page will delete the videos that you choosen. <br>Would you like to confirm or cancel the action?',
        CONFIRM_INSPECTION_DATA: `After confirmation to continue inspection data, you will be directed to the continue inspection data page to fill in the inspection data \n Would you like to confirm or cancel the action?`,
        LEAVE_PAGE: 'Leaving this page will delete all unsaved changes. \nWould you like to leave the page or stay on the action?',
        DELETE_CONFIRMATION: `{{title}} will be permanently deleted. \nWould you like to delete or cancel the action?`,
      }
    },
    BUTTON: {
      ASSIGN_TO: 'Assign to Inspection',
      REFRESH_DATA: 'Refresh Data',
      LETS_GET_STARTED: "Let's Get Started",
      SAVE: 'Save',
      CANCEL: 'Cancel',
      UPLOAD_MIKROSITE: 'Upload Mikrosite',
      NEW_MIKROSITE: 'New Mikrosite',
      DOWNLOAD_DATA_TABLE: 'Download Data Table',
      DOWNLOAD_DATA: 'Download Data',
      EDIT_MICROSITE: 'Edit Mikrosite',
      EDIT_ASSIGN_INSPECTION: 'Edit Assign Inspection',
      SUBMIT: 'Submit',
      UPLOAD_DOCUMENT: 'Upload Document',
      DOWNLOAD: 'Download',
      CONFIRM: 'Confirm',
      TANK_DATA: 'New Tank Data',
      UPLOAD_TANK_DATA: 'Upload Tank Data',
      ADD_COMMENT: 'Add Comment',
      ADD_REVISION: 'Add Revision',
      DOWNLOAD_COI: 'Download COI',
      RESET_FILTER: 'Reset Filter',
    },
    PAGINATION: {
      ENTRY_INFO: 'Showing {{start}} to {{end}} of {{total}} entries',
    },
    PLACEHOLDER: {
      SEARCH: 'Search',
      SEARCH_ROLE_MENU: 'Search role menu',
      SEARCH_MIKROSITE: 'Search Site Name, District, Province',
      SEARCH_INSPECTION: 'Search Site Name, District, Province, Inspection Comp...',
      SEARCH_SITE_ID_NAME: 'Search Site ID, Site Name...'
    },
    TABLE: {
      TH: {
        ROLE_MENU: 'ROLE MENU',
        ACCESS_MENU: 'ACCESS MENU',
        ACTION: 'ACTION',
        LIST_OF_MENU: 'LIST OF MENU',
        CREATE: 'CREATE',
        VIEW: 'VIEW',
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
        MENU: 'MENU',
        ACCESS: 'ACCESS',
        SITE_ID: 'SITE ID',
        SITE_NAME: 'SITE NAME',
        DISTRICT: 'DISTRICT',
        PROVINCE: 'PROVINCE',
        STATUS: 'STATUS',
        SITE_READY_TO_INSPECT: 'SITE READY TO BE INSPECTED',
        INSPECTION_STATUS: 'INSPECTION STATUS',
        INSPECTION_PROGRESS: 'INSPECTION PROGRESS',
        DOCUMENT_STATUS: 'DOCUMENT STATUS',
        COORDINATE: 'COORDINATE',
        INSPECTION_DATE: 'INSPECTION DATE',
        INSPECTION_COMPANY: 'INSPECTION COMPANY',
        DOCUMENT_NAME: 'DOCUMENT NAME',
        LOADING_COMPLETION_DATE: 'LOADING COMPLETION DATE',
        GOV_L: 'GOV (L)',
        GSV_L15: 'GSV (L 15)',
        GOV_L_TOTAL: 'GOV (L) TOTAL',
        GSV_L15_TOTAL: 'GSV (L 15) TOTAL',
        CUSTOMER_REF: 'CUSTOMER REF',
        TRIP_ID: 'TRIP ID',
        CUSTOMER_NAME: 'CUSTOMER NAME',
        TANKI: 'TANK',
        PRODUCT: 'PRODUCT',
        VEHICLE_REGISTERED: 'VEHICLE REGISTERED',
        DRIVER_NAME: 'DRIVER NAME',
        LIFTING_MODA: 'LIFTING MODA',
        REMARK: 'REMARK',
        DENSITY:'DENSITY',
        VCF: 'VCF',
        TEMP: 'TEMP.',
        VEHICLE: 'VEHICLE',
        DRIVER: 'DRIVER',
        DATE: 'DATE',
        OPENING_STOCK: 'OPENING STOCK',
        RECEIVE_IN: 'RECEIVE IN',
        RECEIVE_IN_OTHER: 'RECEIVE IN OTHER',
        CLOSING_STOCK: 'CLOSING STOCK',
        SOUNDING_TANK: 'SOUNDING TANK',
        SOUNDING_PIPE: 'SOUNDING PIPE',
        TOTAL_ACTUAL_STOCK: 'TOTAL ACTUAL STOCK',
        GAIN_LOSS: 'GAIN/LOSS',
        SOUNDING_TIME: 'SOUNDING TIME',
        OPENING_STOCK_GOV: 'OPENING STOCK GOV',
        RECEIVE_IN_GOV: 'RECEIVE IN GOV',
        RECEIVE_IN_OTHER_GOV: 'RECEIVE IN OTHER GOV',
        CLOSING_STOCK_GOV: 'CLOSING STOCK GOV',
        SOUNDING_TANK_GOV: 'SOUNDING TANK GOV',
        SOUNDING_PIPE_GOV: 'SOUNDING PIPE GOV',
        TOTAL_ACTUAL_STOCK_GOV: 'TOTAL ACTUAL STOCK GOV',
        GAIN_LOSS_GOV: 'GAIN/LOSS GOV',
        SOUNDING_TIME_GOV: 'SOUNDING TIME GOV',
        OPENING_STOCK_GSV: 'OPENING STOCK GSV',
        RECEIVE_IN_GSV: 'RECEIVE IN GSV',
        RECEIVE_IN_OTHER_GSV: 'RECEIVE IN OTHER GSV',
        DELIVERY_OUT_GOV: 'DELIVERY OUT GOV',
        DELIVERY_OUT_OTHER_GOV: 'DELIVERY OUT OTHER GOV',
        DELIVERY_OUT_GSV: 'DELIVERY OUT GSV',
        DELIVERY_OUT_OTHER_GSV: 'DELIVERY OUT OTHER GSV',
        CLOSING_STOCK_GSV: 'CLOSING STOCK GSV',
        SOUNDING_TANK_GSV: 'SOUNDING TANK GSV',
        SOUNDING_PIPE_GSV: 'SOUNDING PIPE GSV',
        TOTAL_ACTUAL_STOCK_GSV: 'TOTAL ACTUAL STOCK GSV',
        GAIN_LOSS_GSV: 'GAIN/LOSS GSV',
        SOUNDING_TIME_GSV: 'SOUNDING TIME GSV',
        SKHP_STATUS: 'SKHP STATUS',
        BA_TRACKING: 'BA Tracking',
        BA_SAFETY_TRACKING: 'BA Safety Tracking',
        TW: 'TW',
        MONTH: 'MONTH',
        YEAR: 'YEAR',
        DAYS_NUMBER: 'DAYS NUMBER',
        BBM_TYPE: 'BBM TYPE',
        DISTRIBUTION_VOL: 'DISTRIBUTION VOL.',
        STOCK_VOLUME_L: 'STOCK VOL. [L]',
        CD_MONTH_DAYS: 'CD MONTH [days]',
        TERMINAL: 'TERMINAL',
        DAILY_CD: 'Daily CD',
        COMPANY: 'COMPANY',
        SUB_DISTRICT: 'SUB DISTRICT',
        LATITUDE: 'LATITUDE',
        LONGITUDE: 'LONGITUDE',
        OWNERSHIP: 'OWNERSHIP',
        LOCATION_PERMIT: 'LOCATION PERMIT',
        ENVIRONMENT_PERMIT_NUMBER: 'ENVIRONMENT PERMIT NUMBER',
        ENVIRONMENT_PERMIT: 'ENVIRONMENT PERMIT',
        COI: 'COI',
        COI_NUMBER: 'COI NUMBER',
        OPERATIONAL_STATUS: 'OPERATIONAL STATUS',
        TANK_NUMBERS: 'TANK NUMBERS',
        TANK_CAPACITY: 'TANK CAPACITY',
        DISPENSER_NUMBERS: 'DISPENSER NUMBERS',
        DISTRIBUTION_AREA: 'DISTRIBUTION AREA',
        REPORTED: 'REPORTED',
        PARTNER_NAME: 'PARTNER NAME',
        DISTRIBUTOR_ADDRESS: 'DISTRIBUTOR ADDRESS',
        TBBM_SUPLY: 'TBBM SUPPLY',
        STARTED_OPERATION: 'STARTED OPERATION',
        DOWNLOAD_FILE: 'DOWNLOAD FILE',
        DISTRIBUTOR: 'DISTRIBUTOR',
        STORAGE_NAME: 'Storage Name',
        MINI_DISTRIBUTOR_NUMBER: 'Mikrosite Number', // this specific Mikrosite Number column in Report Builder
        MINI_DISTRIBUTOR_ADDRESS: 'Mikrosite Address', // this specific Penyalur is not Distributor
        MITRA_NAME: 'Distributor Name', // Mitra is also Distributor
        MITRA_COMPANY_TYPE: 'Distributor Company Type',
        NPWP: 'NPWP',
        TDP_NIB: 'TDP_NIB',
        COMMISSIONER: 'Commissioner',
        COMMISSIONER_NPWP: 'Commissioner NPWP',
        DIRECTOR: 'Director',
        DIRECTOR_NPWP: 'Director NPWP',
        DISTRICT_SUB_DISTRICT: 'District Subdistrict',
        PARTNERSHIP_CONTRACT_NUMBER: 'Partnership Contract Number',
        PARTNERSHIP_CONTRACT_DATE: 'Partnership Contract Date',
        PARTNERSHIP_CONTRACT_END_DATE: 'Partnership Contract End Date',
        PARTNERSHIP_CONTRACT_FILE: 'Partnership Contract File',
        PARTNERSHIP_CONTRACT_ADDENDUM_NUMBER: 'Partnership Contract Addendum Number',
        PARTNERSHIP_CONTRACT_ADDENDUM_DATE: 'Partnership Contract Addendum Date',
        PARTNERSHIP_CONTRACT_ADDENDUM_FILE: 'Partnership Contract Addendum File',
        OWNERSHIP_STATUS: 'Ownership Status',
        LOCATION_PERMIT_NUMBER: 'Location Permit Number',
        LOCATION_PERMIT_FILE: 'Location Permit File',
        ENVIRONMENT_PERMIT_FILE: 'ENVIRONMENT PERMIT FILE',
        COI_FILE: 'COI FILE',
        PENYALURAN_HARIAN_L: 'Penyaluran Harian (L)',
        JENIS_BBM: 'JENIS BBM',
        VOLUME_STOK_L: 'Volume Stok (L)',
        TANGGAL: 'TANGGAL',
        NAMA_PENYIMPANAN: 'Nama Penyimpanan',
        NEED_REVISION_TITLE: 'NEED REVISION',
        NEED_REVISION: 'Need Revision',
        DELIVERY_OUT: 'Delivery Out',
        DELIVERY_OUT_OTHER: 'Delivery Out Other',
        SALES: 'Sales',
        STORAGE_FACILITY: 'Storage Facility',
        CLOSING_STOCK_L_VOLUME: 'Closing Stock (L)',
        DAILY_SALES: 'Daily Sales',
      },
    },
    UPLOAD_FILE: {
      TOO_LARGE: 'Document file must be less than {{size}} MB',
      FILE_IS_TOO_LARGE: 'File size is too large',
      INVALID_TYPE: 'Documents uploaded must be in {{types}} format',
      SYSTEM_ERROR: 'Document upload failed due to system problem, please try again',
      SUCCESS: 'Document Uploaded Successfully',
    },
    TOAST: {
      SUCCESS: {
        DRAFT: 'Save As Draft Successfully',
        SUBMIT: 'Submit Successfully',
        DOWNLOAD: 'Download Successfully',
        EDIT: 'Edit Successfully',
        DELETE: 'Deleted Successfully',
      },
      FAILED: {
        DRAFT: 'Save As Draft Failed, Please Try Again',
        SUBMIT: 'Submit Failed, Please Try Again',
        DOWNLOAD: 'Download Failed, Please Try Again',
        EDIT: 'Edit Failed, Please Try Again',
        DELETE: 'Delete Failed, Please Try Again',
        REVISION_MUST_BE_RESOLVED_FIRST: 'Revision must be resolved first',
      },
    },
    ACCESS_MANAGEMENT: {
      EDIT_ROLE: 'Edit Role Access',
    },
    MICROSITE_FORM: {
      SITE_ID: 'Enter Site ID',
      SITE_NAME: 'Enter site name',
      OWNERSHIP: 'Enter ownership name',
      PROVINCE: 'Type province here ...',
      DISTRICT: 'Type district here ...',
      DISTRICT_DESC:
        'Determine the province first before determining the district',
      SUB_DISTRICT: 'Type sub district here ...',
      SUB_DISTRICT_DESC:
        'Determine the district first before determining the sub district',
      SITE_ADDRESS: 'Enter address detail',
      PIN_POINT_LOCATION:
        'You can set the pin point after determining the province, district and sub-district',
      CAPACITY_DATA: 'Enter capacity data',
      OPERATION_STATUS: 'Select operation status',
      DISTRIBUTION_AREA: 'Type distribution area here...',
      TERMINAL_SUPPLY: 'Select terminal supply',
      LAND_COORDINATE_PER_LEASE_AGREEMENT:
        'Enter Land Coordinate Per Lease Agreement',
      LOCATION_PERMIT_ISSUER: 'Enter location permit issuer',
      ENVIRONMENT_PERMIT_ISSUER: 'Enter environment permit issuer',
      ADDRESS: 'Enter address',
      NEXT: 'Next',
      BACK: 'Back',
      CANCEL: 'Cancel',
      SAVE_AS_DRAFT: 'Save As Draft',
      SET_PIN_POINT: 'Set Pin Point',
      SELECT_PROVINCE: 'Select Province',
      SELECT_DISTRICT: 'Select District'
    },
    MICROSITE_DETAIL: {
      SITE_ID: 'Site ID',
      SITE_NAME: 'Site Name',
      OWNERSHIP: 'Ownership',
      SITE_DATA: 'Site Data',
      LEASE: 'Lease',
      DOCUMENT_DATA: 'Document Data',
      SITE_ADDRESS: 'Site Address',
      SUB_DISTRICT: 'Sub District',
      DISTRICT: 'District',
      PROVINCE: 'Province',
      PIN_POINT_LOCATION: 'Pin Point Location',
      COORDINATE: 'Coordinate',
      COORDINATE_LAT: 'Latitude',
      COORDINATE_LONG: 'Longitude',
      SEE_MAP_LOCATION: 'See Map Location',
      SITE_OPERATIONAL_DATE: 'Site Operational Date',
      OPERATIONAL_DATE: 'Operational Date',
      OPERATION_STATUS: 'Operation Status',
      CAPACITY_DATA: 'Capacity Data',
      CAPACITY_DATA_KL: 'Capacity Data (Kilo Liter)',
      FUELS_TYPE: 'Fuels Type',
      PRODUCT_TYPE: 'Product Type',
      TERMINAL_SUPPLY: 'Terminal Supply',
      DISTRIBUTION_AREA: 'Distribution Area',
      LAND_LEASE_AGREEMENT_NUMBER: 'Land Lease Agreement Number ',
      LAND_COORDINATE_PER_LEASE_AGREEMENT: 'Land Coordinate Per Lease Agreement',
      LAND_LEASE_START_DATE: 'Land Lease Start Date',
      LAND_LEASE_END_DATE: 'Land Lease End Date',
      LOCATION_PERMIT: 'Location Permit',
      ENVIRONMENT_PERMIT: 'Environment Permit',
      ENVIRONMENT_PERMIT_ISSUER: 'Environment Permit Issuer',
      DOCUMENT_NUMBER: 'Document Number',
      DOCUMENT_DATE: 'Document Date',
      LOCATION_PERMIT_ISSUER: 'Location Permit Issuer',
      DOWNLOAD_FILE: 'Download File',
      ID_SITE: 'ID Site',
      NAME_SITE: 'Name Site',
      DOCUMENT_STATUS: 'Document Status',
    },
    MICROSITE_UPLOAD: {
      DATA_PREVIEW: '{{name}} Data Preview',
      GUIDE: {
        MUST_BE_CONSIDER: 'Must be consider',
        FILE_MUST_BE: 'Files must be a {{mime}}',
        MAKE_SURE_THE_DATA_IS_MATCH_WITH_FIELD:
          'Make sure the data is match with field',
        MAKE_SURE_THE_FIELD_IS_NOT_EMPTY: 'Make sure the field not empty',
      },
      TABLE: {
        STATUS: 'Status',
        OPERATION_STATUS: 'Operation Status',
        OPERATIONAL_DATE: 'Operational Date',
        DISTRIBUTION_AREA: 'Distribution Area',
        SITE_NAME: 'Site Name',
        OWNERSHIP: 'Ownership',
        PROVINCE: 'Province',
        DISTRICT: 'District',
        SUB_DISTRICT: 'Sub District',
        SITE_ADDRESS: 'Site Address',
        SITE_ID: 'Site ID',
        COORDINATE: 'Coordinate',
        COORDINATE_LAT: 'Coordinate Latitude',
        COORDINATE_LONG: 'Coordinate Longitude',
        CAPACITY_DATA: 'Capacity Data',
        FUEL_TYPE: 'Fuel Type',
        PRODUCT_TYPE: 'Product Type',
        TERMINAL_SUPPLY: 'Terminal Supply',
        LAND_LEASE_AGREEMENT_NUM: 'Land Lease Agreement Number',
        LAND_LEASE_AGREEMENT_COORDINATE: 'Land Lease Agreement Coordinate',
        LAND_LEASE_START_DATE: 'Land Lease Start Date',
        LAND_LEASE_END_DATE: 'Land Lease End Date',
        LOCATION_DOC_NUMBER: 'Location Document Number',
        LOCATION_PERMIT_ISSUER: 'Location Permit Issuer',
        LOCATION_DOC_DATE: 'Location Document Date',
        LOCATION_ADDRESS: 'Location Address',
        ENV_DOC_NUMBER: 'Environment Document Number',
        ENV_PERMIT_ISSUER: 'Environment Permit Issuer',
        ENV_NAME_SITE: 'Environment Name Site',
        ENV_DOC_DATE: 'Environment Document Date',
        ENV_ID_SITE: 'Environment Site ID',
        ENV_ADDRESS: 'Environment Address',
      },
    },
    MEDIA: {
      MICROSITE_FUEL_GENERAL_VIEW: 'Mikrosite Fuel General',
      DISPENSER_AREA: 'Dispenser Area',
      NAME_PLATE_DISPENSER: 'Name Plate Dispenser',
      TERA_CALIBRATION_STICKER: 'Tera/Calibration Sticker',
      FUEL_TANK_NUMBER: 'Fuel Tank Number',
      FUEL_TANK_NAME_PLATE: 'Fuel Tank Name Plate',
      GROUNDING_CONNECTION: 'Grounding Connection',
      DISPENSER_MOTOR_AND_PUMP_CONDITION: 'Dispenser Motor and Pump Condition',
      TANK_UL_MANUFACTURER_NAME_PLATE: 'Tank UL Manufacturer Name Plate',
      MID: 'MID',
      OIL_CATCHER: 'Oil Catcher',
      APAR_DI_AREA_DISPENSER: 'APAR di Area Dispenser',
      APAR_DI_AREA_BENGKEL_DAN_TOKO: 'APAR di Area Bengkel & Toko',
      TITIK_BERKUMPUL: 'Titik Berkumpul',
      WARNING_SIGN: 'Warning Sign',
      LAMPU_PENERANGAN: 'Lampu Penerangan',
      ERP: 'ERP',
      ABOVE_TANK: 'Above Tank',
      CLOSE_UP_ON_INDOSTATION: 'Close up on Indostation',
      ELECTRICAL_PANEL_ROUTER_AND_CONTROLLER: 'Electrical Panel, Router & Controller',
      PLN_METER_INCL_TOILET: 'PLN Meter, Incl. Toilet',
      CONCRETE_BOX_FOR_USED_BOTTLES: 'Concrete Box for used Bottles',
      INFILTRATION_WELL: 'Infiltration Well (if any)'
    },
    MEDIA_STATUS: {
      MAJOR: 'Major Finding',
      MINOR: 'Minor Finding'
    },
    MICROSITE_STATUS: {
      AWAIT_DOC_UPLOAD: 'Pending Doc Upload',
      ASSIGNED_INSPECTION: 'Assigned Inspection',
      INSPECTION_PROCESS: 'Inspection Process',
      COI_ISSUED: 'COI Issued',
      COI_PROCESS: 'COI Process',
      COMPLETE_ESSENTIAL_DOC: 'Complete Permit',
      NEED_DATA_REVISION: 'Need Data Revision',
      NEED_DOCUMENT_REVISION: 'Need Document Revision',
      BA_INSPECTION: 'Inspection Complete',
      SUBMITTED: 'Submitted',
      DRAFT: 'Draft',
      APPROVED: 'Approved',
      REJECTED: 'Revision Required',
    },
    OPERATION_STATUS: {
      CIVIL_WORK: 'Civil Work',
      READY_FOR_DEPLOYMENT: 'Ready for Deployment',
      UNIT_DEPLOYED: 'Unit Deployed',
      TERA_FINISHED: 'Tera Finished',
      HOLD: 'Hold',
      DROP: 'Drop',
      OPERATIONAL: 'Operational',
      READY_TO_INSPECT: 'Ready to Inspect',
      CLOSED: 'Closed',
    },
    INSPECTION_STATUS: {
      UNASSIGNED: 'Unassigned',
      ASSIGNED_INSPECTION: 'Assigned Inspection',
      INSPECTION_PROCESS: 'Inspection Process',
      INSPECTION_COMPLETED: 'Inspection Complete',
    },
    COI_STATUS: {
      COMPLETE: 'Complete',
      NOT_COMPLETE: 'Not Complete',
      EXPIRED: 'Expired',
      EXPIRED_SOON: 'Expired Soon'
    },
    MICROSITE_TABS: {
      SUBMITTED: 'Submitted',
      DRAFT: 'Draft',
      REJECTED: 'Revision Required',
      APPROVED: 'COI Issued',
      ELIGIBLE_MAP: 'Eligible for MAP',
    },
    INSPECTION: {
      TAB: {
        FOR_REVIEW: 'For Review',
        CONTINUE: 'Inspection Process',
        COMPLETE: 'Inspection Completed',
        REVISION: 'Revision Required',
        MICROSITE_DATA: 'Mikrosite Data',
        INSPECTION_DATA: 'Inspection Data'
      },
      FORM: {
        INSPECTION_STATUS: 'Inspection Status',
        INSPECTION_ASSIGN_DATE: 'Inspection Assign Date',
        INSPECTION_DATE: 'Inspection Date',
        INSPECTION_BOOK_UPLOAD_DATE: 'Inspection Book Upload Date',
        DISPENSER_ID: 'Dispenser ID',
        FUEL_UNIT_ID: 'Fuel Unit ID',
        UL_MENUFACTURER_ID: 'UL Manufacturer ID',
        NUMBER_OF_DISPENSER: 'Number of Dispenser',
        NUMBER_OF_TANK: 'Number of Tank',
        TANK_CAPACITY: 'Tank Capacity',
        VOLUME_UNIT: 'Volume unit : Kilo Liters',
        MANDAROTY_IMG: 'Mandatory Image',
        ADDITIONAL_IMG: 'Additional Image',
        ADDITIONAL_VIDEO: 'Additional Video',
        OTHER_ADDITIONAL_IMG: 'Other Additional Image',
        MIGAS_OFFICER: "Migas Officer",
        INSPECTOR: "Inspector",
        ASSIGNMENT_LETTER_TO_MIGAS: "Assignment Letter to Migas",
        APPOINTMENT_LETTER_FROM_MIGAS: " Appointment Letter from Migas",
        UPLOAD_APPOINTMENT_LETTER_FROM_MIGAS: "Upload  Appointment Letter from Migas",
        UPLOAD_ASSIGNMENT_LETTER_TO_MIGAS: "Upload Assignment Letter to Migas",
        UPLOAD_BA_INSPECTION: "Upload BA Inspection",
        UPLOAD_BA_SAFETY_INSPECTION: "Upload BA Safety Inspection",
        INVOICE_NO: 'Invoice No',
        INVOICE_DATE: 'Invoice Date',
        UNIT_RATE: 'Unit Rate (IDR)',
        INVOICE_VALUE: 'Invoice Value',
        PO_NUMBER: 'PO Number',
        COI_ACCELERATE: 'COI Accelerate IC Claim',
        PO_CODE: 'PO Code',
        PO_PROGRESS: 'PO Progress',
        PO_DESC: 'PO Code Description',
        TANK_SERIAL_NUMBER: 'Tank Serial Number',
      },
      ERROR_FUEL_UNIT_DUPLICATE: 'This Fuel Unit ID has been input in other Inspection Data',
      TICKET_CATEGORY: 'Ticket Category',
      CREATE_TICKET_IMAGE: 'Create ticket for this image',
      CREATE_TICKET_VIDEO: 'Create ticket for this video',

      // PROBABLY UNUSED
      FOR_REVIEW: 'review',
      CONTINUE: 'continue inspection',
      COMPLETE: 'complete data',

      CONFIRMATION_INSPECTION: 'Continue to Inspection Data Input?',
      CONFIRMATION_APPROVE: 'Continue Inspection Data',
      CONFIRMATION_REJECT: 'Revision Required',
      REJECTION_TYPE: 'Type Rejection',
      REJECTION_NOTE: 'Give the note',
      DOCUMENT_LETTER: 'Document Letter',
      INSPECTION_DATA: 'Inspection Data',
      SITE_IMAGES: 'Site Images',
      BA_INSPECTION: 'BA Inspection',
      BA_SAFETY_INSPECTION: 'BA Safety Inspection'
    },
    COI: {
      FORM: {
        COI_DATE: "COI Date",
        COI_NUMBER: "COI Number",
        COI_EXPIRED_DATE: "COI Expired Date",
        COI_STATUS: "COI Status",
        COI_ISSUED_DATE: "COI Issued Date",
        COI_ISSUED_NUMBER: "COI Issued Number",
        COI_UPLOAD_DATE: "COI Upload Date",
        COI_RENEWAL_NUMBER: "COI Renewal Number",
        BA_SENT_MIGAS: "BA Safety Sent to Migas",
      },
      EDIT_COI: "Edit COI",
      UPLOAD_COI: "Upload COI",
      COI_HISTORY: 'COI History',
    },
    COI_RENEWAL: {
      EXPIRED_SOON: 'Expired Soon',
      DOC_EXPIRED: 'Doc Expired',
      INFORMATION_COI_RENEWAL: 'Review the mikrosite data thoroughly before proceeding to renewal',
      VIEW_FOR_RENEWAL: 'View for Renewal',
      CONFIRMATION_RENEWAL: 'Do you want to continue the renewal process?',
      CONTINUE_PROCESS: 'Continue Process',
    },
    PREPARE_INSPECTION_BOOK: {
      UNDEFINED: 'Unsent',
      DEFINED: 'Sent',
      DOCUMENT_TRACKING: 'Document Tracking',
      PIB_DETAIL: 'Prepare Inspection Book Detail',
      SEND_TO_MIGAS: 'Send to Migas',
      INSPECTION_EMLI: 'Inspection send to EMLI',
      EMLI_MIGAS: 'EMLI send to DG Migas',
      DOWNLOAD_ALL_DOC: 'Download All Document',
      COI_ISSUED: 'COI Issued',
      FORM: {
        CODE: 'Code',
        DOCUMENT_NAME: 'Document Name',
        DOCUMENT_SOURCE: 'Document Source',
        DOCUMENT_FILE: 'Document File',
        ACTION: 'Action',
      },
    },
    DOCUMENT_SOURCE: {
      DOCUMENT_TEMPLATE: 'Document Template',
      MANUAL_UPLOAD: 'Manual Upload',
      FROM_SYSTEM: 'From System',
      FROM_SKHP_DOCUMENT: 'From SKHP Document',
    },
    TERMINAL_RECEIVE_REPORT: {
      TERMINAL_NAME: 'Terminal Name',
      ACTIVE: 'Active',
      EDIT_TANK: 'Edit Tank',
      NEW_TANK: 'New Tank',
      TANK_STATUS: 'Tank Status',
      TANK_NAME: 'Tank Name',
      RECEIVE_IN: 'Receive In',
      RECEIVE_IN_OTHER: 'Receive In Other',
      RECEIVE_IN_TOTAL_GSV: 'Receive In Total GSV',
      RECEIVE_IN_TOTAL_GOV: 'Receive In Total GOV',
      RECEIVE_IN_OTHER_TOTAL_GSV: 'Receive In Other Total GSV',
      RECEIVE_IN_OTHER_TOTAL_GOV: 'Receive In Other Total GOV',
      SOUNDING_TANK: 'Sounding Tank',
      SOUNDING_PIPE: 'Sounding Pipe',
      TOTAL_ACTUAL_STOCK: 'Total Actual Stock',
      GAIN_LOSS: 'Gain/Loss',
      SOUNDING_TIME: 'Sounding Time',
    },
    DATA_MONITORING: {
      OPENING_STOCK: 'Opening Stock',
      SUPPLY_TOTAL: 'Supply Total',
      STOCK_TRANSFER_TOTAL: 'Stock Transfer Total',
      CLOSING_STOCK: 'Closing Stock',
      AVERAGE_CLOSING_STOCK: 'Average Closing Stock',
      AVERAGE_CLOSING_STOCK_DAILY: 'Average Closing Stock',
      END_OF_CURRENT_MONTH: 'End Of Current Month',
      SALES_TOTAL: 'Sales Total',
      GAIN_LOSS_TOTAL: 'Gain/(Loss) Total',
      MONTHLY_CD: 'Monthly CD',
      QUARTERLY_CD: 'Quarterly CD',
      PRODUCT_NAME: 'Product Name',
      STORAGE_NAME: 'Storage Name',
      RECEIVE_IN: 'Receive In',
      RECEIVE_IN_OTHER: 'Receive In Other',
      DELIVERY_OUT: 'Delivery Out',
      DELIVERY_OUT_OTHER: 'Delivery Out Other',
      PRODUCT_TYPE_JENIS_BBM: 'Product Type',
      SALES_TOTAL_TOTAL_PENYALURAN: 'Sales Total',
    },
    DOCUMENT_LICENSE: {
      LABEL: {
        OPERATIONAL_DATE: 'Operational Date',
        SITE_ID: 'Site ID',
        SITE_NAME: 'Site Name',
        SITE_ADDRESS: 'Site Address',
        SITE_READY_DATE: 'Site Ready To Be Inspected',
        DOCUMENT_DATE: 'Document Date',
        DOCUMENT_NUMBER: 'Document Number',
        DOCUMENT_VALIDITY: 'Document Validity',
        STATUS_DOCUMENT: 'Status Document',
        UPLOAD_FILE: 'Upload File',
        LOCATION_PERMIT: 'Location Permit',
        LOCATION_PERMIT_ISSUER: 'Location Permit Issuer',
        ENVIRONMENT_PERMIT: 'Environment Permit',
        ENVIRONMENT_PERMIT_ISSUER: 'Environment Permit Issuer',
        SKHP: 'SKHP',
        SKHP_ISSUER: 'SKHP Issuer',
        BA_TERA_ISSUER: 'BA Tera Issuer',
        BA_TERA: 'BA Tera',
        BA_TERA_DATE: 'BA Tera Date',
        AS_BUILT_DRAWING: 'As Built Drawing',
        DRAWING_NUMBER: 'Drawing Number',
        COMMISSIONING_REPORT: 'Commissioning Report',
        CONTRACTOR_NAME: 'Contractor Name',
        MDR: 'MDR',
        DOCUMENT_FILE: 'Document File',
        UPLOAD_DATE: 'Upload Date',
        ID_SITE: 'ID Site',
        NAME_SITE: 'Name Site',
        ACTION: 'Action',
        LOCATION_PERMIT_HISTORY: 'Location Permit History',
        ENVIRONMENT_PERMIT_HISTORY: 'Environment Permit History',
        SKHP_HISTORY: 'SKHP History',
        BA_TERA_HISTORY: 'BA Tera History',
        AS_BUILT_DRAWING_HISTORY: 'As Built Drawing History',
        COMMISSIONING_REPORT_HISTORY: 'Commissioning Report History',
        MDR_HISTORY: 'MDR History',
        SEE_HISTORY: 'See History',
        LIST_OF_NOT_COMPLETE_DOCUMENT: 'List of not complete document',
        MISMATCHED_ADDRESS: 'Mismatched address with the document file',
        MISMATCHED_SITEID: 'Mismatched Site ID with the document file',
        SKHP_RENEWAL_DOCUMENT: 'SKHP Renewal Document'
      },
      STATUS: {
        COMPLETE: 'COMPLETE',
        NOT_COMPLETE: 'NOT COMPLETE',
      },
    },
    DOCUMENT_STATUS: {
      COMPLETE: 'COMPLETE',
      NOT_COMPLETE: 'NOT COMPLETE',
    },
    UPLOAD_STATUS: {
      UPLOADED: 'Uploaded',
      NOT_UPLOADED: 'Not Uploaded',
      EXPIRED: 'Expired',
      EXPIRED_SOON: 'Expired Soon',
    },
    LIST_TO_ASSIGN: {
      LABEL: {
        SITE_ID: 'Site ID',
        SITE_NAME: 'Site Name',
        DISTRICT: 'District',
        PROVINCE: 'Province',
        ASSIGN_TO_COMPANY: 'Assign to Inspection Company',
        PO_CODE: 'PO Code',
        ASSIGN_LETTER: 'Assignment Letter to Migas',
        ASSIGN_LETTER_UPLOAD: 'Upload Assigment Letter to Migas',
        UPLOAD: 'Upload List',
      },
      PLACEHOLDER: {
        SITE_ID: 'Input Site ID',
        SITE_NAME: 'Input Site Name',
        DISTRICT: 'Input District',
        PROVINCE: 'Input Province',
        ASSIGN_TO_COMPANY: 'Input Assign to Inspection Company',
        PO_CODE: 'Input PO Code',
        ASSIGN_LETTER: 'Input Assignment Letter to Migas'
      }
    },
    SIGN_BA_INSPECTION: {
      SITE_INFO: 'Site Info',
      SITE_ID: 'Site ID',
      SITE_NAME: 'Site Name',
      SITE_ADDRESS: 'Site Address',
      DISTRICT: 'District',
      PROVINCE: 'Province',
      INSPECTION_COMPANY: 'Inspection Company',
      STATUS: {
        COMPLETE: 'COMPLETE',
        NOT_COMPLETE: 'NOT COMPLETE',
      },
    },
    BA_DOCUMENT_STATUS: {
      UPLOADED: 'UPLOADED',
      NOT_UPLOAD: 'NOT YET UPLOAD'
    },
    DOCUMENT_TRACKING: {
      SEND_DOCUMENT: 'Send Document',
      RECEIVE_BY_DISTRIBUTOR: 'Receive by Distributor',
      SIGNED_BY_DISTRIBUTOR: 'Send Document Signatured by Distributor',
      RECEIVE_BY_EMLI: 'Receive by EMLI',
      SIGNED_BY_EMLI: 'Send Document Signatured by EMLI',
    },
    DG_MIGAS: {
      EMPTY_DATA_MESSAGE: 'Data is still empty<br>Please do a filter first to display the data',
      DOWNLOAD_XLS: 'Download .xls only',
      DOWNLOAD_DOC: 'Download document only'
    },
    INSPECTION_BOOK_MIGAS: {
      PAGE_DESC: 'This page site is showing Safety Inspection Result on Mini Fuel Station / Mikrosite as part of applicable regulatory compliance and submitted to Directorate General Oil & Gas through letters which the numbers & dates are mentioned within below table view.'
    },
    ERROR: {
      NOT_LOGGED_IN: 'You are not signed in',
      NOT_LOGGED_IN_MSG: 'Please try to sign in again or if problem persists contact administrator.',
      UNAUTHORIZED_PAGE: 'Forbidden Page',
      UNAUTHORIZED_PAGE_MSG: 'Your account is not authorized to access this page. Please contact your administrator for more info.',
      GET_PROFILE: 'System fails to retrieve your profile. Please contact the administrator staff.',
      FIELD_EMPTY: '{{fieldName}} cannot be empty',
      MEDIA_EMPTY: 'image cannot be empty',
      MISMATCHED_ERR: 'Mismatched {{fieldName}} with the mikrosite data.',
      MISMATCHED_ERR_COI: 'Mismatched {{fieldName}} with the mikrosite data. Please contact distributor.',
    },
    INSPECTION_REMARKS: {
      FROM_INSPECTION_COMPANY: 'From Inspection Company',
      FROM_DISTRIBUTOR: 'From Distributor',
      FROM_EMLI: 'From EMLI',
    },
    COMMON: {
      COMPLETE: 'Complete',
      NOT_COMPLETE: 'Not Complete',
      GOTO_HOME: 'Return Home',
      GOTO_LOGIN: 'Back to Login',
      DOCUMENT_LETTER_MIGAS: 'Document Letter to Migas',
      DOWNLOAD_DOCUMENT: 'Download Document',
      LETTER_DOCUMENT: 'Letter Document',
      LETTER_NUMBER: 'Letter Number',
      LETTER_DATE: 'Letter Date',
      BA_SIGN_MIGAS_DATE: "BA Safety Signed by Migas Date",
      BA_SENT_MIGAS_DATE: "BA Safety Sent to Migas Date",
      VALID: 'Valid',
      EXPIRED: 'Expired',
      EXPIRED_SOON: 'Expired Soon',
      ACTIVE: 'Active',
      SKHP_STATUS: 'SKHP Status',
      LAND_LEASE_END_DATE: 'Land Lease End Date',
      OPERATOR_PHONE_NO: 'Operator Phone Number',
      OPERATOR_NAME: 'Operator Name',
      PO: 'PO',
      INSPECTION_COMPANY: 'Inspection Company',
      INSPECTION_COMPANY_ID: 'Inspection Company ID',
      INSPECTION_RESULT: 'Inspection Result',
      TOTALIZER_MECHANIC: 'Totalizer (Mechanic)',
      TOTALIZER_DIGITAL: 'Totalizer (Digital)',
      CLOSE: 'Close',
      DATE_FIELD: 'Date field',
      FROM_DATE: 'From Date',
      TO_DATE: 'To Date',
      DATE: 'Date',
      NUMBER: 'Number',
      MICROSITE_ID: 'Mikrosite ID',
      DISTRIBUTOR_ADDRESS: 'Distributor\'s Address',
      DISTRICT_OR_CITY: 'District/City',
      PROVINCE: 'Province',
      OWNERSHIP_STATUS: 'Ownership Status',
      LOCATION_PERMIT: 'Location Permit',
      ENVIRONMENT_PERMIT: 'Environment Permit',
      DOC_SAFETY: 'Doc. Safety',
      NO_OF_TIMBUN_TANKS: 'Number of Timbun Tanks',
      CAPACITY_KL: 'Capacity (KL)',
      DISPENSER: 'Dispenser',
      DISTRIBUTION_AREA: 'Distribution Area',
      TYPE_OF_FUEL_PRODUCT_DISTRIBUTED: 'Type of Fuel Product Distributed',
      TYPE_OF_FUEL: 'Type of Fuel',
      MICROSITE_DETAIL: 'Mikrosite Detail',
      NO_IMAGE: 'No Image',
      OPERATION_STATUS: 'Operation Status',
      REJECT_REASON: 'Reject Reason',
      COMMENT: 'Comment',
      MAJOR: 'Major Finding',
      MINOR: 'Minor Finding',
      TITLE: 'Title',
      UPLOAD_IMAGE: 'Upload Image',
      CLICK_UPLOAD_IMAGE: 'Click to upload image',
      CLICK_TO_CHOOSE_IMAGE: 'Click to choose image',
      UPLOAD_VIDEO: 'Upload Video',
      CLICK_UPLOAD_VIDEO: 'Click to upload video',
      CLICK_TO_CHOOSE_VIDEO: 'Click to choose video file',
      SELECT_ALL_IMAGE: 'Select All Image',
      SELECT_ALL_VIDEO: 'Select All Video',
      FILTER_BY_INSPECTION_PROGRESS: 'Filter by Inspection Progress',
      FILTER_BY_INSPECTION_STATUS: 'Filter by Inspection Status',
      FILTER_BY_COI_STATUS: 'Filter by COI Status',
      FILTER_BY_DOC_STATUS: 'Filter by Document Status',
      FILTER_BY_STATUS: 'Filter by Status',
      ACCESS: '{{suffix}} access',
      UNDEFINED_ACCESS: 'Undefined menu access yet',
      DEFINE_ROLE_ACCESS: 'Define Role Access',
      EDIT_ROLE_ACCESS: 'Edit Role Access',
      ALL_ACCESS: 'All Access',
      BACK: 'Back',
      CREATE: 'Create',
      VIEW: 'View',
      VIEW_FOR_REVIEW: 'View for Review',
      ADD_IINSPECTION_DATA: 'Add Inspection Data',
      UPDATE: 'Update',
      DELETE: 'Delete',
      NO_DATA: 'No available data',
      NO_DATA_DISPLAY_FOR: 'No data is displayed for ',
      LIST_OF_NOT_COMPLETE_DOC: 'LIST OF NOT COMPLETE DOCUMENT',
      BA_INSPECTION_DOCUMENT_TRACKING: 'BA Inspection Document Tracking',
      BA_SAFETY_INSPECTION_DOCUMENT_TRACKING: 'BA Safety Inspection Document Tracking',
      ENTER: 'Enter',
      LOADING_COMPLETION_DATE: 'Loading Completion Date',
      DOWNLOAD_FILE: 'Download File',
      DELETE_FILE: 'Delete File',
      PREVIEW_FILE: 'Preview File',
      DOCUMENT_FILE: 'Document File',
      DOCUMENT_STATUS: 'Document Status',
      INVALID_FORM: 'Please complete all mandatory fields properly',
      SITE_ID: 'Site ID',
      SITE_NAME: 'Site Name',
      SITE_READY_TO_INSPECT: 'Site Ready To Be Inspected',
      SITE_OPERATIONAL_DATE: 'Site Operational Date',
      INSPECTION_DATE: 'Inspection Date',
      DISTRICT: 'District',
      STATUS: 'Status',
      PO_CODE: 'PO Code',
      UPLOAD_DOCUMENT: 'Upload Document',
      SUBMIT: 'Submit',
      FILTER_BY_DATE: 'Filter by Date',
      APPLY: 'Apply',
      RESET: 'Reset',
      APPLY_FILTER: 'Apply Filter',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      UPLOAD_DATE: 'Upload Date',
      SIGNATURE_DATE: 'Signature Date',
      ASSIGNMENT_LETTER: 'Assignment Letter',
      APPOINTMENT_LETTER: 'Appointment Letter',
      ASSIGNMENT_LETTER_TO_MIGAS: 'Assignment Letter to Migas',
      APPOINTMENT_LETTER_FROM_MIGAS: 'Appointment Letter from Migas',
      MANDATORY_IMAGES: 'Mandatory Images',
      ADDITIONAL_IMAGES: 'Additional Images',
      OTHER_ADDITIONAL_IMAGES: 'Other Additional Images',
      ADDITIONAL_VIDEO: 'Additional Video',
      VIEW_MORE: 'View {{count}} More',
      DELETE_DATA: 'Delete Data',
      VIEW_DETAIL: 'View Detail',
      VIEW_DETAILS: 'View Details',
      VIEW_DETAIL_DATA: 'View Detail Data',
      CORRECT: 'Correct',
      DATA_FAILED: '{{total}} Data Failed',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      SAVE: 'Save',
      MONTH: 'Month',
      YEAR: 'Year',
      VIEW_TANK_DATA: 'View Tank Data',
      OWNERSHIP: 'Ownership',
      SITE_DATA: 'Site Data',
      LEASE: 'Lease',
      SITE_IMAGES: 'Site Images',
      INSPECTION_DATA: 'Inspection Data',
      DOCUMENT_LETTER: 'Document Letter',
      REMARKS: 'Remarks',
      SYSTEM_ERROR: 'An unexpected error occurred while trying to process your request. Please contact the system administrators.',
      NOTIFICATION: 'Notification',
      UNREAD: 'Unread',
      LEAVE_PAGE: 'Leave Page',
      STAY: 'Stay',
      DRAG_FILES_HERE: 'Drag files here',
      DRAG_TO_CHANGE: 'Click or drag file here to change',
      CLICK_TO_CHOOSE_FILE: 'Or click to choose file',
      FILE: 'File:',
      DOWNLOAD_XLS_TEMPLATE: 'Download .xls file template',
      ARE_YOU_SURE_TO_SUBMIT: 'Are you sure to submit?',
      RENEWAL_NO: 'No. Renewal',
      BY_EMAIL_SLASH_NAME: 'By (Email/Name)',
      UPLOADED_BY: 'Uploaded By',
      REPORT_TYPE: 'Report Type',
      PRODUCT_TYPE: 'Product Type',
      TERMINAL_SUPPLY: 'Terminal Supply',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      SENT: 'Sent',
      UNSENT: 'Unsent',
      SEND_TO_MIGAS: 'Send to Migas',
      DG_MIGAS_SENT_DATE: 'Report DG Migas Sent Date',
      COI_DATE: 'COI Date',
      SENT_DATE: 'Sent Date',
      LOADING_COMPLETION_START_DATE: 'Loading Completion Start Date',
      LOADING_COMPLETION_END_DATE: 'Loading Completion End Date',
      PRODUCT: 'Product',
      LIFTING_MODA: 'Lifting Moda',
      CHOOSE_MONTH: 'Choose Month',
      CHOOSE_YEAR: 'Choose Year',
      CHOOSE_PRODUCT: 'Choose Product',
      CHOOSE_LIFTING: 'Choose Lifting',
      CHOOSE_UNIT: 'Choose Unit',
      ALL_PRODUCT: 'All Product',
      ALL_LIFTING: 'All Lifting',
      TERMINAL_NAME: 'Terminal Name',
      ALL_TERMINAL_NAME: 'All Terminal Name',
      FILTER_BY_PRODUCT: 'Filter by Product',
      CHOOSE_TYPE: 'Choose Type',
      ALL_TYPE: 'All Type',
      ALL_TERMINAL: 'All Terminal',
      INSPECTION_COMMENTS: 'Inspection Comments',
      INSPECTION_MAJOR_COMMENT: 'Inspection Major Comment',
      INSPECTION_MAJOR_COMMENT_CLOSE_OUT: 'Inspection Major Comment Close Out',
      INSPECTION_MINOR_COMMENT: 'Inspection Minor Comment',
      INSPECTION_MINOR_COMMENT_CLOSE_OUT: 'Inspection Minor Comment Close Out',
      COMMENT_BEEN_CLOSED: 'Comment has been closed',
      INSPECTION_REMARKS: 'Inspection Remarks',
      REVISION_COMMENT: 'Revision Comment',
      YES: 'Yes',
      NO: 'No',
      ANSWER: 'Answer',
      ENTER_COMMENT: 'Enter Comment',
      DATE_TYPE: 'Date Type',
      SELECT_DATE_TYPE: 'Select Date Type',
      COI_STATUS: 'COI Status',
      SELECT_COI_STATUS: 'Select COI Status',
      COI_EXPIRED_DATE: 'COI Expired Date',
      COI_UPLOAD_DATE: 'COI Upload Date',
      SELECT_CATEGORY: 'Select Category',
      ENTER_URL: 'Enter URL',
      URL: 'URL',
      REVISION_REQUIRED_BA_INSPECTION: 'Revision Required: BA Inspection',
      REVISION_REQUIRED_BA_SAFETY_INSPECTION: 'Revision Required: BA Safety Inspection',
    },
    GUIDE: {
      HOW_TO_UPLOAD_SIGN_BA_INSPECTION_DOC: 'How to upload sign BA Inspection document?',
      HOW_TO_UPLOAD_SIGN_BA_SAFETY_INSPECTION_DOC: 'How to upload sign BA Safety Inspection document?',
      DOWNLOAD_SIGN_BA_INSPECTION_DOC: 'Download BA Inspection document, click button below',
      DOWNLOAD_SIGN_BA_SAFETY_INSPECTION_DOC: 'Download BA Safety Inspection document, click button below',
      DOWNLOAD_BA_INSPECTION: 'Download BA Inspection',
      DOWNLOAD_BA_SAFETY_INSPECTION: 'Download BA Safety Inspection',
      ENTER_SIGN_INTO_DOC: 'Enter signature into document',
      UPLOAD_SIGNED_BA_IN_THE_UPLOAD_FIELD: 'Upload the signed BA Inspection document in the upload field next to it',
      UPLOAD_SIGNED_BA_SAFETY_IN_THE_UPLOAD_FIELD: 'Upload the signed BA Safety Inspection document in the upload field next to it',
      DOWNLOAD_BA_BEFORE_UPLOAD: 'Please Download BA Inspection before Uploading',
      DOWNLOAD_BA_SAFETY_BEFORE_UPLOAD: 'Please Download BA Safety Inspection before Uploading',
      LOGGED_OUT: 'You are signed out',
      INSPECTION_COMMENT_URL_INPUT: 'Document link for collaborative purpose',
      NEED_REVISION: 'Need revision from Inspection Company'
    }
  },
};
