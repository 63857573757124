// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  azureKey: {
    clientId: '10d8ed46-554d-494e-a074-4279d4bde41a',
    // authority: 'https://login.microsoftonline.com/organizations'
    authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906'
    // authority: 'https://login.microsoftonline.com/e106c41b-e051-42ed-b016-4a2459a32ad7'
  },
  redirectUrl: 'https://acc.im2finity.com/',
  postLogoutUrl: 'https://acc.im2finity.com/',
  REST_API: 'https://api-uat.im2finity.com/api/',
  appVersion: 'v8.1.8',
  msalScope: 'api://10d8ed46-554d-494e-a074-4279d4bde41a/api',
  mapKey: {
    authType: "subscriptionKey",
    subscriptionKey: "2mTr2yrlufE4yNPyfl17gG35iEYGLp9awonYxfqcBqY0APYO3xhJJQQJ99AHAC8vTInwtgPZAAAgAZMP9Pcd"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

